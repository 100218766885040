import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/organisms/Seo/Seo';
import Layout from '../components/organisms/Layout/Layout';
// import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import PageBannerPolicy from '../components/atoms/PageBannerPolicy/PageBannerPolicy';
import PageLayout from '../components/atoms/Contentful/ContentfulPageLayout';
import * as styles from './careers.module.css';
import Container from '../components/atoms/Container/Container';
import CareerForm from '../components/molecules/HubspotCareerForm/CareerForm';
import VerticalCarousel from '../components/atoms/VerticalCarousel/VerticalCarousel';

const CareersContentful = ({ data }) => {
  const { pageName, pageTitle, metaTitle } = data.careersPage;
  const page = data.careersPage;
  const contentfulPage = (page.blocks && page.blocks[0]) || false;
  const contentfulSections = (contentfulPage && contentfulPage.blocks) || false;
  const blockAlignment =
    (contentfulPage && contentfulPage.alignment.alignment) || false;
  const blockWidth =
    (contentfulPage.blockWidth && contentfulPage.blockWidth.blockWidth) ||
    false;
  // const textColour = contentfulPage.textColour || false;
  const blockPaddingTopBottom =
    (contentfulPage.paddingTopBottom &&
      contentfulPage.paddingTopBottom.paddingTopBottom.paddingTopBottom) ||
    false;
  const layoutStyle = (page.logoStyle && page.logoStyle.logoStyle) || false;
  const genClass = pageName
    ? 'contentful-page page-' +
      pageName
        .trim()
        .replace('.', '')
        .replace(/[^A-Z0-9]+/gi, '-')
        .toLowerCase()
    : '';
  const dataHelmet = { pageName, pageTitle, metaTitle };
  // const sliderSection = {
  //   ...contentfulSections[8],
  //   slideSpeed: 4000,
  //   autoPlay: true,
  // };
  const seoTitle = page.seoTitle || page.pageName;
  // const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <Layout
      varHelmet={dataHelmet}
      genClass={genClass}
      layoutStyle={layoutStyle}
      className={`${styles.container}  `}>
      <Seo
        title={seoTitle}
        description={page.metaDescription}
        image={
          page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''
        }
      />

      <PageBannerPolicy title={pageTitle} description={metaTitle} />
      {contentfulSections?.map((section, sectionIndex) => {
        // console.log("🚀 ~ file: careers.js:61 ~ {contentfulSections?.map ~ section", section)
        let blockStyles = `${styles[section.className] ?? ''}`;
        let customSection = section;
        switch (section?.internal?.type) {
    
          case 'ContentfulBlockSlides':
            blockStyles += ` ${styles.careersTestimonials}`
            break;
          case "ContentfulBlockImage":
            return (
              <div className={styles.homeWithArtworkImage} key={sectionIndex}>
                <img src={section.desktopImage.file.url} alt='' />
              </div>
            );
          case 'ContentfulBlockAniParallaxScrolling':
            return (
              <div className={styles.carouselContainer} key={sectionIndex}>
                <VerticalCarousel section={section} />
              </div>
            );

          default:
            break;
        }

        return (
          <div
            key={sectionIndex}
            data-components
            data-index={sectionIndex}
            data-alignment={blockAlignment}
            style={{ width: blockWidth }}
            className={blockStyles}
            data-last={
              sectionIndex === contentfulSections.length - 1 ? true : false
            }>
            <PageLayout
              key={sectionIndex}
              pageName={pageName}
              section={customSection}
              sectionIndex={sectionIndex}
              paddingTopBottom={blockPaddingTopBottom}
              breadCrumbs={page.breadcrumbs}
            />
          </div>
        );
      })}
      <div className={styles.careerForm}>
        <Container size={'medium'}>
          <CareerForm />
        </Container>
      </div>
    </Layout>
  );
};

export default CareersContentful;
export const query = graphql`
  query ContentfulPageCareers {
    careersPage: contentfulPage(pageName: { eq: "Careers" }) {
      pageName
      seoTitle
      pageTitle
      metaCanonicalLink
      metaDescription
      metaTitle
      metaPreviewImage {
        file {
          url
        }
      }
      breadcrumbs
      logoStyle {
        logoStyle
      }
      blocks {
        textColour
        backgroundColour
        alignment {
          alignment
        }
        blockWidth {
          blockWidth
        }
        paddingTopBottom {
          paddingTopBottom {
            paddingTopBottom
          }
        }
        blocks {
          ... on ContentfulBlockImage {
            id
            internal {
              type
            }
            desktopImage {
              file {
                fileName
                url
              }
            }
          }
          ... on ContentfulBlockButtons {
            name
            internal {
              type
            }
            buttons {
              name
              buttonText
              deepLink
              url
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
          ... on ContentfulBlockSlides {
            name
            classes
            internal {
              type
            }
            blocks {
              name
              blockWidth {
                blockWidth
              }
              alignment {
                alignment
              }
            }
            backgroundColour
            slide {
              name
              heading
              subText {
                childMarkdownRemark {
                  html
                }
              }
              image {
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                imageMaxWidth
              }
              vimeoId
              backgroundColor
              button {
                buttonText
                deepLink
                name
                url
              }
              coloredSubText {
                textColor
                subText
              }
            }
            buttonName
            buttonUrl
            heading
            slideStyle {
              slideStyle
            }
            slideToShow {
              slideToShow
            }
            textColour {
              textColour {
                textColour
              }
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            textAlignment {
              textAlignment
            }
          }
          ... on ContentfulBlockSingleCard {
            name
            internal {
              type
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            backgroundColour
            vimeoId
            content {
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              heading
              headingSize {
                headingSize
              }
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              textAlignment {
                textAlignment
              }
              textColour
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulBlockDoubleCards {
            name
            internal {
              type
            }
            title
            backgroundColour
            className
            textColour {
              textColour {
                textColour
              }
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blocks {
              alignment {
                alignment
              }
              blockWidth {
                blockWidth
              }
              backgroundColour
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            blockAlignment {
              blockAlignment
            }
            mobileRevert
            contentLeft {
              heading
              childContentfulBlockContentContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              headingSize {
                headingSize
              }
              textAlignment {
                textAlignment
              }
              textColour
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundColour
            }
            contentRight {
              heading
              childContentfulBlockContentContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              headingSize {
                headingSize
              }
              textAlignment {
                textAlignment
              }
              textColour
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundColour
            }
          }
          ... on ContentfulBlockContent {
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            heading
            headingSize {
              headingSize
            }
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            className
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textAlignment {
              textAlignment
            }
            imagePosition {
              imagePosition
            }
            internal {
              type
            }
            content {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulBlockAniParallaxScrolling {
            name
            heading
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            internal {
              type
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textColour {
              textColour {
                textColour
              }
            }
            style {
              style
            }
            stickyContent {
              headingSize {
                headingSize
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              imagePosition {
                imagePosition
              }
              name
              textAlignment {
                textAlignment
              }
              textColour
              heading
              className
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
              backgroundColour
              backgroundImage {
                imageMaxWidth
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              blockAlignment {
                blockAlignment
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
            }
            scrollingContents {
              headingSize {
                headingSize
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              imagePosition {
                imagePosition
              }
              name
              textAlignment {
                textAlignment
              }
              textColour
              heading
              headingColor
              className
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
              backgroundColour
              backgroundImage {
                imageMaxWidth
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              blockAlignment {
                blockAlignment
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
            }
          }
          ... on ContentfulBlockTiles {
            name
            internal {
              type
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockStyle {
              blockStyle
            }
            backgroundColour
            textColour {
              textColour {
                textColour
              }
            }
            title
            tile {
              ... on ContentfulBlockContent {
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                heading
                headingSize {
                  headingSize
                }
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                textAlignment {
                  textAlignment
                }
                content {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              ... on ContentfulBlockDoubleCards {
                name
                internal {
                  type
                }
                backgroundColour
                textColour {
                  textColour {
                    textColour
                  }
                }
                blocks {
                  alignment {
                    alignment
                  }
                  blockWidth {
                    blockWidth
                  }
                }
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                blockAlignment {
                  blockAlignment
                }
                mobileRevert
                contentLeft {
                  heading
                  childContentfulBlockContentContentTextNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  buttonName
                  buttonUrl
                  buttonStyle {
                    buttonStyle
                  }
                  name
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  headingSize {
                    headingSize
                  }
                  textAlignment {
                    textAlignment
                  }
                  textColour
                  imagePosition {
                    imagePosition
                  }
                  imageHoverAnimation {
                    imageHoverAnimation
                  }
                }
                contentRight {
                  heading
                  childContentfulBlockContentContentTextNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  buttonName
                  buttonUrl
                  buttonStyle {
                    buttonStyle
                  }
                  name
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  headingSize {
                    headingSize
                  }
                  textAlignment {
                    textAlignment
                  }
                  textColour
                  imagePosition {
                    imagePosition
                  }
                  imageHoverAnimation {
                    imageHoverAnimation
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

