import React from 'react';

const Clip = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7288 8.5L8.87358 14.2574C8.0644 15.053 6.96692 15.5 5.82258 15.5C3.4396 15.5 1.50781 13.6005 1.50781 11.2574C1.50781 10.1321 1.9624 9.05301 2.77158 8.25736L8.8013 2.32843C9.34075 1.79799 10.0724 1.5 10.8353 1.5C12.424 1.5 13.7118 2.76633 13.7118 4.32843C13.7118 5.07857 13.4088 5.79799 12.8693 6.32843L7.01407 12.0858C6.74434 12.351 6.37852 12.5 5.99707 12.5C5.20274 12.5 4.55881 11.8668 4.55881 11.0858C4.55881 10.7107 4.71034 10.351 4.98007 10.0858L10.6608 4.5" stroke="#7B2CBF" stroke-width="1.2"/>
    </svg>

  );
};

export default Clip;