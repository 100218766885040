async function getFormIOApi (jsonFormData) {
  const formData = new FormData();
  Object.keys(jsonFormData).forEach(key => {
    formData.append(key, jsonFormData[key]);
  });
  return await fetch(process.env.GATSBY_GET_FORM_IO_URL, {
    method: "POST",
    body: formData,
  }).then(async response => ({ response: response}))
    .catch(error => console.log(error))
}

export default getFormIOApi;