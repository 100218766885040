import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as styles from './VerticalCarousel.module.css';
import Slick from 'react-slick';
import { Container } from "@material-ui/core";
import { useWindowDimensions } from "../../../helpers/general";

const SlideItem = ({ section, slideContent, index, sectionHeader, setActive, active }) => {
  const { backgroundColour, backgroundImage, heading, headingColor, content} = slideContent;

  return (
    <div className={styles.slideItem} style={{backgroundColor: `#${backgroundColour}`}}>
      <Container size={'medium'}>
        <div className={styles.indicators}>
          {section.scrollingContents.map((content, index) => (
            <div className={`${styles.indicatorNumber} ${active === index ? styles.activeIndicator : ''}`} role="presentation" onClick={() => setActive(index)}>
              <p style={{color: headingColor ? `#${headingColor}` : '#000'}}><span className="txt">{`0${index + 1}`}</span></p>
            </div>)
          )}
        </div>
        <h2 style={{color: headingColor ? `#${headingColor}` : '#000'}}>{sectionHeader}</h2>
        <div className={styles.slideContentContainer}>
          <div>
            <div className={styles.heading}>
              <h3>{heading}</h3>
            </div>
            <div className={styles.content}
                 dangerouslySetInnerHTML={{__html: content.childMarkdownRemark.html}}
            ></div>
          </div>
          <div className={styles.contentImage}>
            <img src={backgroundImage.desktopImage.file.url} alt='' />
          </div>
        </div>
      </Container>
    </div>
  );
}

const VerticalCarousel = ({ section }) => {
  const containerRef = useRef(null);
  // const contentRef = useRef(null);
  const [active, setActive] = useState(0);
  const [stopParallax, setStopParallax] = useState(false);
  const { width, height } = useWindowDimensions();
  const isMobile = width < 768;
  const isLandscape = width > height;

  const settings = {
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    centerPadding: "10px",
    draggable: false,
    infinite: false,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    vertical: true,
    speed: 800,
    autoplaySpeed: 2000,
    useTransform: true,
    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    adaptiveHeight: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          vertical: false,
          infinite: true,
          swipe: true,
          touchMove: true,
        }
      }],
    afterChange: (index) => { setActive(index) }
  };
  const slickRef = useRef(null);
  // const scrollListener = useCallback((event) => {
  //   const container = containerRef.current;
  //   const top = container.offsetTop - container.scrollTop + container.clientTop;
  //   const bottom = container.clientHeight + top;

  //   if ((!isMobile && isLandscape) && !stopParallax && top < document.documentElement.scrollTop && bottom > document.documentElement.scrollTop) {
  //     // window.onscroll = () => { window.scroll({
  //     //   top: top,
  //     //   left: 0,
  //     //   behavior: 'instant'
  //     // })};
  //     slickRef.current.slickNext();
  //   }
  // }, [isLandscape, isMobile, stopParallax]);

  useEffect(() => {
      window.onscroll = () => {
        const container = containerRef.current;
        const top = container.offsetTop - container.scrollTop + container.clientTop;
        const bottom = container.clientHeight + top;
        if ((!isMobile && isLandscape) && !stopParallax && top < document.documentElement.scrollTop && bottom > document.documentElement.scrollTop) {
          window.scroll({
            top: top,
            left: 0,
            behavior: 'instant'
          })
          slickRef.current.slickNext();
        }
      };
  }, [stopParallax]);

  // useEffect(() => {
  //   // document.addEventListener('scroll', scrollListener);
  // }, []);

  // useEffect(() => {
  //   if (stopParallax) {
  //     // document.removeEventListener('scroll', scrollListener);
  //     // window.onscroll = null;
  //   }
  // }, [stopParallax, ]);

  useEffect(() => {
    slickRef.current.slickGoTo(active);
    if (active === section.scrollingContents.length - 1) {
      setStopParallax(true);
    } else {
      setStopParallax(false);
    }
  },[active, section]);

  return <>
    <div className={styles.container} ref={containerRef}>
      <div className={styles.slider}>
        <Slick {...settings} ref={slickRef}>
          {section.scrollingContents.map((content, index) => <SlideItem active={active} section={section} slideContent={content} index={index} sectionHeader={section.heading} setActive={setActive}/>)}
        </Slick>
      </div>
    </div>
  </>
};

export default VerticalCarousel;