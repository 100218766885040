// extracted by mini-css-extract-plugin
export var fileDetails = "CareerForm-module--fileDetails--s-a2B";
export var dialogContent = "CareerForm-module--dialogContent--ZsG+A";
export var formContainer = "CareerForm-module--formContainer--1OsV6";
export var formGroup = "CareerForm-module--formGroup--atb84";
export var formGroupMulti = "CareerForm-module--formGroupMulti--k5CGD";
export var heading = "CareerForm-module--heading--VmLvn";
export var submitButton = "CareerForm-module--submitButton--DsM+x";
export var spinnerWrapper = "CareerForm-module--spinnerWrapper--6w-im";
export var fileError = "CareerForm-module--fileError--CPJGI";
export var fileName = "CareerForm-module--fileName--sRKvb";
export var fileSize = "CareerForm-module--fileSize--T5VdI";
export var formFooter = "CareerForm-module--formFooter--NcHiu";