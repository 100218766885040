import React, { useRef } from 'react';
import * as styles from './HubspotFormInput.module.css';
import Clip from "../../../assets/svg/clip";

const HubspotFormInput = React.forwardRef((props, ref) => {
  const { fieldType, hidden, label, name, required, validation, errors } = props;
  const fileRef = useRef(null);
  // console.log(errors, name);
  const inputField = (fieldType, name, hidden, required, placeHolder, ref) => {
    let type = fieldType;
    let pattern = '';
    if (fieldType === 'single_line_text' || fieldType === 'text' || fieldType === 'input') {
      type = 'text';
    } else if (fieldType === 'phone') {
      type = 'tel';
      if (validation) {
        pattern = `[0-9]{${validation.minAllowedDigits}, ${validation.maxAllowedDigits}}`;
      }
    } else if (hidden) {
      type = 'hidden';
    } else if (fieldType === 'multi_line_text') {
      return <textarea
        name={name}
        id={`hs-${name}`}
        className={styles.textArea}
        placeholder={placeHolder}
        onChange={(event) => props.onChange(event, name)}
      ></textarea>
    } else if (fieldType === 'file') {
      return (
        <div className={styles.cvContainer}>
          <div className={styles.innerCvContainer}>
            <a className={styles.attachBtn} role="presentation" onClick={() => {
              return fileRef.current.click();
            }}>Attach CV <Clip /></a>
            <input
              accept={'.pdf'}
              type={type}
              name={name}
              id={`hs-${name}`}
              className={`${styles.inputField} ${styles.inputFile}`}
              {...pattern}
              ref={fileRef}
              required={required}
              onChange={(event) => props.onChange(event, name)}
            />
          </div>
        </div>
      );
    }

    return <input
      type={type}
      name={name}
      id={`hs-${name}`}
      className={styles.inputField}
      {...pattern}
      required={required}
      onChange={(event) => props.onChange(event, name)}
    />
  }
  const fieldError = errors.find(error => error.field === name);
  console.log(fieldError);
  return (
    <div className={styles.formField}>
      {
        fieldType !== 'file' && (
          <label htmlFor={`hs-${name}`} className={styles.label}>
            {label}
          </label>
        )
      }

      {inputField(fieldType, name, hidden, required, props.placeholder, ref)}
      {fieldError && <div className={styles.error}>{fieldError.error}</div>}
    </div>
  );
});

export default HubspotFormInput;