import React, { useCallback, useEffect, useRef, useState } from 'react';
import { hubspotApi } from "../../../helpers/hubspot";
import HubspotFormInput from "../../atoms/HubspotFormInput/HubspotFormInput";
import * as styles from './CareerForm.module.css';
// import { getCookie } from "../../../helpers/general";
import getFormIOApi from "../../../helpers/getformio";
import Dialog from "../../atoms/Dialog/Dialog";
import Loader from "../../atoms/Loader/Loader";

const FormGroup = (props) => {
  const { fields, width } = props;
  return <div className={width > 1 ? styles.formGroupMulti : styles.formGroup}>
    {
      fields.map((field) => {
        return <HubspotFormInput {...field} onChange={props.onChange} errors={props.errors}/>
      })
    }
  </div>;
}

const CareerForm = () => {
  const [form, setForm] = useState({
    fieldGroups: false
  });
  const [fileName, setFileName] = useState(null);
  const [formValues, setFormValues] = useState({})
  const [errors, setErrors] = useState([{}]);
  const [showDialog, setShowDialog] = useState(false);
  const [msg, setMessage] = useState({
    error: {},
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {

    if (!errors.length) {
      setIsSubmitting(true);
      getFormIOApi(formValues).then(resp => {
        if (resp.response.status === 200) {
          setMessage({
            ...msg,
            dialogSuccess: resp.response.status
          })
        }
      });
    }
  }, [errors, formValues, msg]);

  const validateForm = () => {
    let fieldGroups = form.fieldGroups;
    const errorBag = [];
    fieldGroups.forEach((fieldGroup, index) => {
      if (fieldGroup.fields) {
        fieldGroup.fields.forEach((field, fieldIndex) => {
          if (!formValues[field.name]) {
            errorBag.push({field: field.name, error: field.label  + ' is required'})
          } else if (field.fieldType === 'email') {
            const re = /\S+@\S+\.\S+/;
            if (!re.test(formValues[field.name])) {
              errorBag.push({field: field.name, error: field.label  + ' should be a valid email'})
            }
          } else if (field.validation) {
            const validation = field.validation;
            if (validation.maxAllowedDigits && validation.maxAllowedDigits < formValues[field.name].length) {
              errorBag.push({field: field.name, error: field.label + ' should be less than ' + validation.maxAllowedDigits});
            } else if (validation.minAllowedDigits && validation.minAllowedDigits > formValues[field.name].length) {
              errorBag.push({field: field.name, error: field.label + ' should be greater than ' + validation.minAllowedDigits});
            }
          }
        });
      }
    });
    setErrors(errorBag);
  }
  const onHandleChange = useCallback((event, name) => {
    let value = event.target.value;
    if (name === 'applicant_cv') {
      value = event.target.files[0];
      setFileName(value);
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }, [formValues]);

  useEffect(() => {
    handleSubmit();
  }, [errors, handleSubmit]);

  useEffect(() => {
    const localStorageForm = localStorage.getItem('careerForm');

    if (!localStorageForm) {
      hubspotApi(`hubspotCareer`, 'GET').then(response => {
        if ((response && response.status === 200) || response.status === 201) {
          setForm(response.response);
          localStorage.setItem('careerForm', JSON.stringify(response.response));
        } else {
          console.error(response);
        }
      });
    } else {
      setForm(JSON.parse(localStorageForm));
    }
  }, []);

  useEffect(() => {
    if (showDialog) {
      setFormValues({});
      formRef.current.reset();
      setFileName(null);
    }
  }, [showDialog]);

  useEffect(() => {
    if (msg.dialogSuccess) {
      setIsSubmitting(false);
      setShowDialog(true);
    }
  }, [msg]);

  useEffect(() => {
    if (form.fieldGroups) {
      const { fieldGroups } = form;
      let fields = fieldGroups.reduce((acc, curr) => {
        let fieldObject = {};
        if (curr.fields) {
          curr.fields.forEach(field => fieldObject[field.name] = '');
        }

        acc = {...acc, ...fieldObject};
        return acc;
      }, {});
      setFormValues(fields);
    }
  }, [form]);

  const fieldGroups = form && form.fieldGroups && form.fieldGroups;
  const fileSize = fileName && fileName.size ? fileName.size / 1000000 : 0;


  return (
    <div className={styles.formContainer}>
      <form ref={formRef}>
        {fieldGroups && fieldGroups.map((fieldGroup) => {
          const {fields} = fieldGroup;

          if (fieldGroup.richText) {
            return <div className={styles.heading} dangerouslySetInnerHTML={{__html: fieldGroup.richText}} />;
          }

          if (fields[0].fieldType === 'file') {
            return (
              <div className={styles.formFooter}>
                <div>
                  <FormGroup fields={fields} width={fields.length} onChange={onHandleChange} errors={errors}/>
                  {
                    fileName && (
                      <div className={styles.fileDetails}>
                        {fileSize > 20 && <div className={styles.fileError}>
                          Should be less 10MB
                        </div>}

                        <div className={styles.fileName}>{fileName.name}</div>
                        <div className={styles.fileSize}>{(fileName.size / 1000) < 1000 ? (fileName.size / 1000).toFixed(2) + 'KB' : (fileName.size / 1000000).toFixed(2) + 'MB'}</div>
                      </div>
                    )
                  }
                </div>
                <div>
                  <button className={styles.submitButton} type={'button'} onClick={() => validateForm()}>Submit</button>
                </div>
              </div>
            );
          }

          return fields && <FormGroup fields={fields} width={fields.length} onChange={onHandleChange} errors={errors}/>
        })}
      </form>
      <Dialog
        hideBtnCancel={true}
        size="sm"
        open={showDialog}
        hideBtnOk={false}
        title="Hi there!"
        onCancel={() => setShowDialog(false)}
        okBtnText={'Thanks!'}
      >
        {msg.dialogSuccess && (
          <>
            <p className={styles.dialogContent}>
              Thank you for sending through your information.
            </p>
            <p className={styles.dialogContent}>
              It’s great to see that you are interested in joining the green energy revolution with us!
            </p>
          </>
        )}
        {msg.dialogError && (
          <p className={styles.dialogContent}>{msg.dialogError}</p>
        )}
      </Dialog>
      {isSubmitting && (
        <div className={styles.spinnerWrapper}>
          <div>
            <Loader size={'medium'}/>
          </div>
        </div>
      )}

    </div>
  );
};

export default CareerForm;