// extracted by mini-css-extract-plugin
export var indicatorNumber = "VerticalCarousel-module--indicatorNumber--fsR8K";
export var heading = "VerticalCarousel-module--heading--JmTHu";
export var container = "VerticalCarousel-module--container--SUiHY";
export var slideItem = "VerticalCarousel-module--slideItem--GOqVR";
export var slider = "VerticalCarousel-module--slider--UIKli";
export var contents = "VerticalCarousel-module--contents--aSI80";
export var slideContentContainer = "VerticalCarousel-module--slideContentContainer--Vs4fu";
export var indicators = "VerticalCarousel-module--indicators--AgTeQ";
export var content = "VerticalCarousel-module--content--EKonl";
export var contentImage = "VerticalCarousel-module--contentImage--VEmp3";
export var contentContainer = "VerticalCarousel-module--contentContainer--EgJM+";
export var activeIndicator = "VerticalCarousel-module--activeIndicator--1bCJL";